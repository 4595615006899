import { FunctionComponent, useState } from 'react';
import Image from 'next/image';

import { Routes } from 'types/routes';

import { withVariables } from 'utils/string';

import PointedArrowRightPurple from 'theme/icons/pointed-arrow-right-purple.svg';

import Button from 'components/layout/Button';
import { ButtonIconTypes, ButtonStyles, ButtonVariants } from 'components/layout/Button/types';
import Link from 'components/layout/Link';

import StyledComponent from './styles';
import { Props } from './types';

const SectionArticlesSingleArticle: FunctionComponent<Props> = ({ articles, translations }) => {
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);

    const firstArticle = articles?.elements[0];
    if (!firstArticle) return null;

    return (
        <StyledComponent className="single-article">
            <div className="single-article-container">
                <div className="single-article-container__image">
                    <Image
                        layout="fill"
                        src={firstArticle?.image?.imageUrl || 'images/home/placeholder_post-1.png'}
                        onLoad={() => imageLoaded ? null : setImageLoaded(true)}
                        alt="post-img"
                    />
                    {imageLoaded && (
                        <Image
                            className="single-article-container__image-background"
                            layout="fill"
                            loading="lazy"
                            src={firstArticle?.image?.imageUrl || '/images/home/palceholder_card_image.png'}
                            alt="bg"
                        />
                    )}
                </div>
                <div className="single-article-container__information">
                    <div className="header">
                        <div className="article-data">
                            <div className="date">
                                {firstArticle?.publishedAt}
                            </div>
                            <div className="category">
                                {firstArticle?.category?.name}
                            </div>
                            <div className="author">
                                {firstArticle?.author?.name || '-'}
                            </div>
                        </div>
                        <div className="article-title">
                            {firstArticle?.title}
                        </div>
                    </div>
                    <div className="body">
                        <div
                            className="article-short-description"
                            dangerouslySetInnerHTML={{ __html: firstArticle?.content }}
                        />
                    </div>
                    <div className="footer">
                        <div className="controls">
                            <Link href={withVariables(Routes.PublicArticles, { slug: firstArticle?.slug })}>
                                <Button
                                    className="learn-more-button"
                                    style={ButtonStyles.Secondary}
                                    variant={ButtonVariants.Tertiary}
                                    label={'Czytaj więcej'}
                                    icon={{
                                        type: ButtonIconTypes.Image,
                                        value: PointedArrowRightPurple.src,
                                        position: 'after',
                                    }}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </StyledComponent>
    );
};

export default SectionArticlesSingleArticle;
