import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    padding: 4em 0 8em;
    min-height: 400px;

    .triple-article-container {
        display: flex;
        gap: 2em;

        &__left {
            flex: 1 1 100%;

            .post-card {
                display: flex;
                flex-direction: column;
                border-radius: 5px;
                overflow: hidden;
                height: 100%;
                min-height: 20em;
                background-color: ${vars.backgroundWhite};

                // lazy utils
                .p-4 {
                    padding: 4em;
                }

                &__image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    position: relative;
                    z-index: 1;

                    img {
                        height: 100%;
                        object-fit: contain;
                        z-index: 1;

                        &.background {
                            object-fit: cover;
                            z-index: -1;
                            filter: blur(8px);
                            transform: scale(1.2);
                        }
                    }
                }

                &__content {
                    height: 100%;

                    .wrapper {
                        background-color: ${vars.backgroundWhite};
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;;
                        gap: 1em;

                        .date-category {
                            display: flex;
                            align-items: center;
                            font-size: 0.9em;
                            flex-wrap: wrap;

                            .date {
                                margin-right: 6px;
                                padding-right: 6px;
                                border-right: 2px solid ${vars.colorSecondary};
                                font-weight: 700;
                            }

                            .category {
                                color: ${vars.colorSelectHover};
                                font-weight: 700;
                            }
                        }

                        .title {
                            font-size: 1.5em;
                            line-height: 1.2;
                            font-weight: 700;
                        }

                        .learn-more {
                            display: flex;
                            margin-top: auto;

                            .button {
                                font-size: 1.5em;
                                color: ${vars.colorTertiary};
                            }
                        }
                    }
                }
            }
        }

        &__right {
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;
            gap: 2em;

            .post-card {
                border-radius: 5px;
                overflow: hidden;
                display: flex;
                min-height: 20em;
                height: 100%;
                background-color: ${vars.backgroundWhite};

                &:nth-of-type(2) {
                    .post-card__image {
                        order: 2;
                    }
                }

                &__image {
                    flex: 1 1 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    position: relative;
                    z-index: 1;

                    img {
                        height: 100%;
                        object-fit: contain;
                        z-index: 1;

                        &.background {
                            object-fit: cover;
                            z-index: -1;
                            filter: blur(8px);
                            transform: scale(1.2);
                        }
                    }
                }

                &__content {
                    flex: 1 1 calc(100% - 4em);
                    padding: 2em;
                    background-color: ${vars.backgroundWhite};
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 1em;

                    .date-category {
                        display: flex;
                        align-items: center;
                        font-size: 0.8em;
                        flex-wrap: wrap;

                        .date {
                            margin-right: 6px;
                            padding-right: 6px;
                            border-right: 2px solid ${vars.colorSecondary};
                            font-weight: 700;
                        }

                        .category {
                            color: ${vars.colorSelectHover};
                            font-weight: 700;
                        }
                    }

                    .title {
                        font-size: 1.5em;
                        line-height: 1.2;
                        font-weight: 700;
                    }

                    .learn-more {
                        display: flex;
                        margin-top: auto;

                        .button {
                            font-size: 1.5em;
                            color: ${vars.colorTertiary};
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.desktopXS}) {
        .triple-article-container {
            &__right {
                .post-card {
                    &__content {
                        .title {
                            font-size: 1.3em;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.tabletL}) {
        .triple-article-container {
            flex-direction: column;

            &__left {
                .post-card {
                    .p-4 {
                        padding: 2em;
                    }

                    &__image {
                        min-height: 16em;
                        height: 16em;

                        img {
                            width: 100%;
                        }
                    }

                    &__content {
                        min-height: 14em;

                        .wrapper {
                            min-height: 14em;
                        }
                    }
                }
            }

            &__right {
                .post-card {
                    flex-direction: column;

                    &:nth-of-type(2) {
                        .post-card__content {
                            order: 2;
                        }
                    }

                    &__image {
                        min-height: 16em;
                        height: 16em;

                        img {
                            max-height: 16em;
                            width: 100%;
                        }
                    }

                    &__content {
                        height: 14em;
                        min-height: 14em;

                        .wrapper {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        .triple-article-container {
            &__left {
                .post-card {
                    .p-4 {
                        padding: 2em;
                    }

                    &__content {
                        .title {
                            font-size: 1.4em;
                        }
                    }
                }
            }

            &__right {
                .post-card {
                    display: flex;

                    &__image {
                        max-height: 16em;

                        img {
                            width: 100%;
                        }
                    }

                    &__content {
                        .date-category {
                            display: flex;
                            align-items: center;
                        }

                        .title {
                            font-size: 1.4em;
                        }
                    }
                }
            }
        }
    }
`;
