import { FunctionComponent, useState } from 'react';
import Image from 'next/image';

import ListCollection from 'types/redux/ListCollection';
import { Routes } from 'types/routes';

import Article from 'models/Article';

import { LIST_MARKUP } from 'consts/api';
import { withVariables } from 'utils/string';

import { list as listArticles } from 'requests/articles';

import PointedArrowRightLight from 'theme/icons/pointed-arrow-right-light.svg';
import PointedArrowRightPurple from 'theme/icons/pointed-arrow-right-purple.svg';

import Button from 'components/layout/Button';
import { ButtonIconTypes, ButtonStyles, ButtonVariants } from 'components/layout/Button/types';
import Link from 'components/layout/Link';

import StyledComponent from './styles';
import { Props } from './types';

//TODO: add loader, and eventually pagination
const SectionArticleListArticle: FunctionComponent<Props> = ({
    articles,
    translations,
    excludeFirstArticle,
}) => {
    const [articleList, setArticleList] = useState<ListCollection<Article>>(articles);
    const moreArticlesAvailable = articles?.meta?.lastPage > 1 &&
        articleList?.meta?.currentPage !== articleList?.meta?.lastPage;

    const loadMoreArticles = async () => {
        try {
            await Promise.all([
                listArticles({ page: 1, perPage: 99 }),
            ]).then(response => {
                const gatherAllArticles = [...articleList.elements, ...response[0].articles.elements];
                const articlesWithoutDuplicates = gatherAllArticles.filter((article, index, self) => {
                    return index === self.findIndex((t) => t.id === article.id);
                });

                setArticleList({ ...LIST_MARKUP, elements: articlesWithoutDuplicates });
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <StyledComponent className="section-articles-list">
            <div className="list-article">
                {articleList?.elements?.map((article, idx) => (excludeFirstArticle ? idx > 0 : true) && (
                    <div
                        className="article-card-container"
                        key={article?.id || idx}
                    >
                        <div className="article-card-container__top">
                            <div className="article-card-container__top-image">
                                <Image
                                    layout="fill"
                                    src={article?.image?.imageUrl || '/images/home/placeholder_post-1.png'}
                                    alt="card-image"
                                />
                                <Image
                                    className="article-card-container__top-image__background"
                                    layout="fill"
                                    loading="lazy"
                                    src={article?.image?.imageUrl || '/images/home/placeholder_post-1.png'}
                                    alt="card-image"
                                />
                            </div>
                        </div>
                        <div className="article-card-container__bottom">
                            <div className="article-card-container__bottom-header">
                                <div className="date">
                                    {article?.publishedAt}
                                </div>
                                <div className="category">
                                    {article?.category?.name}
                                </div>
                                <div className="author">
                                    {article?.author?.name || '-'}
                                </div>
                            </div>
                            <div className="article-card-container__bottom-body">
                                {article?.title}
                            </div>
                            <div className="article-card-container__bottom-footer">
                                <div className="controls">
                                    <Link href={withVariables(Routes.PublicArticles, { slug: article.slug })}>
                                        <Button
                                            className="learn-more-button"
                                            style={ButtonStyles.Secondary}
                                            variant={ButtonVariants.Tertiary}
                                            label={'Czytaj więcej'}
                                            icon={{
                                                type: ButtonIconTypes.Image,
                                                value: PointedArrowRightPurple.src,
                                                position: 'after',
                                            }}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {moreArticlesAvailable && (
                <div className="load-more-articles">
                    <Button
                        className="learn-more-button"
                        style={ButtonStyles.Secondary}
                        variant={ButtonVariants.Tertiary}
                        label={'Pokaż Więcej'}
                        icon={{
                            type: ButtonIconTypes.Image,
                            value: PointedArrowRightLight.src,
                            position: 'after',
                        }}
                        onClick={() => loadMoreArticles()}
                    />
                </div>
            )}
        </StyledComponent>
    );
};

export default SectionArticleListArticle;
