import moment from 'moment';

import ApiArticle from 'types/api/Article';
import ArticleAuthor from 'types/api/ArticleAuthor';
import FileUpload from 'types/api/FileUpload';

import ArticleCategory from 'models/ArticleCategory';
import Model from 'models/Model';

import { ARTICLE_DEFAULT_FORMAT } from 'consts/dates';

export default class Article implements Model {
    id: string;
    title: string;
    slug: string;
    content: string;
    publishedAt: string;
    category: ArticleCategory;
    image: FileUpload;
    author: ArticleAuthor;
    shortDescription: string;

    constructor(data: ApiArticle) {
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.content = data.content;
        this.publishedAt = data.publishedAt && moment(data.publishedAt).locale('pl').format(ARTICLE_DEFAULT_FORMAT);
        this.category = data.category && new ArticleCategory(data.category);
        this.image = data.image;
        this.author = data.author;
        this.shortDescription = data.shortDescription;
    }
}
