import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    .headline {
        color: ${vars.colorTextWhite};
        font-size: 3.4em;
        text-align: center;
        margin-bottom: 1em;
    }
`;
