import ListCollection from 'types/redux/ListCollection';

import Article from 'models/Article';

export enum ArticleType {
    List = 'list',
    Single = 'single',
    Triple = 'triple',
}

export interface Props {
    translations?: {
        headline?: string;
        subheadline?: string;
        articleCtaButton?: string;
    }
    articles: ListCollection<Article>;
    visibleMoreArticlesButton?: boolean;
    articleType: ArticleType;
    excludeFirstArticle?: boolean;
}
