import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    .list-article {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2em;

        .article-card-container {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            overflow: hidden;

            &__top {
                min-height: calc(180px + 4vw);
                max-height: 280px;

                &-image {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    z-index: 1;
                    background-color: ${vars.backgroundWhite};

                    img {
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                        z-index: 1;

                        &.article-card-container__top-image__background {
                            object-fit: cover;
                            z-index: -1;
                            transform: scale(1.2);
                            filter: blur(8px);
                        }
                    }
                }
            }

            &__bottom {
                background-color: ${vars.backgroundWhite};
                height: 100%;
                padding: 2em;
                display: flex;
                flex-direction: column;

                &-header {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 0.9em;
                    margin-bottom: 1em;

                    & > * {
                        font-weight: 600;
                    }

                    .date,
                    .category {
                        position: relative;
                        margin-right: 5px;
                        padding-right: 5px;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 2px;
                            border-radius: 2px;
                            height: 60%;
                            right: -1px;
                            top: 20%;
                            background-color: ${vars.backgroundDarkGray};
                        }
                    }

                    .category {
                        color: #400E4E;
                    }
                }

                &-body {
                    font-size: 1.5em;
                    font-weight: 600;
                    line-height: 1.28;
                    margin-bottom: 1em;
                }

                &-footer {
                    margin-top: auto;

                    .controls {
                        .layout-link {
                            .layout-button {
                                justify-content: flex-start;
                                font-size: 1.5em;

                                .button {
                                    gap: 0.5em;

                                    .button-content {
                                        color: ${vars.colorTertiary}
                                    }

                                    .button-icon-image {
                                        width: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .load-more-articles {
        display: flex;
        justify-content: center;
        margin-top: 4em;

        .layout-button {
            .button {
                font-size: 1.5em;
                gap: 0.5em;

                .button-content {
                    color: ${vars.colorTextWhite};
                }

                .button-icon-after {
                    transform: rotate(90deg);
                    width: 16px;
                }
            }
        }
    }

    @media all and (max-width: ${vars.tabletL}) {
        .list-article {
            grid-template-columns: 1fr 1fr;

            .article-card-container {
                &__bottom {
                    padding: 1.5em;
                }
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        .list-article {
            grid-template-columns: 1fr;

            .article-card-container {
                &__top {
                    min-height: 200px;
                    max-height: 200px;
                }

                &__bottom {
                    padding: 2em;
                }
            }
        }
    }
`;
