import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    .single-article-container {
        display: flex;
        border-radius: 10px;
        overflow: hidden;
        min-height: 30em;
        background-color: ${vars.backgroundWhite};

        &__image {
            flex: 1 1 60%;
            height: auto;
            max-height: 600px;
            position: relative;
            z-index: 1;

            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }

            &-background {
                opacity: 1;
                z-index: -1;
                object-fit: cover !important;
                transform: scale(1.2);
                filter: blur(8px);
            }
        }

        &__information {
            flex: 1 1 40%;
            min-width: 400px;
            background-color: ${vars.backgroundWhite};
            padding: 2.5em;
            display: flex;
            flex-direction: column;

            .header {
                margin-bottom: 1em;

                .article-data {
                    display: flex;
                    font-size: 0.9em;
                    margin-bottom: 16px;
                    flex-wrap: wrap;

                    & > * {
                        font-weight: 600;
                    }

                    .date,
                    .category {
                        position: relative;
                        margin-right: 5px;
                        padding-right: 5px;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 2px;
                            border-radius: 2px;
                            height: 60%;
                            right: -1px;
                            top: 20%;
                            background-color: ${vars.backgroundDarkGray};
                        }
                    }

                    .category {
                        color: ${vars.colorCategoryPurple}
                    }
                }

                .article-title {
                    font-size: 1.5em;
                    font-weight: 600;
                    max-width: 480px;
                    line-height: 1.28;
                }
            }

            .body {
                max-width: 480px;
                font-size: 1.1em;
                margin-bottom: 1em;

                .article-short-description {
                    display: -webkit-box;
                    -webkit-line-clamp: 12;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    p, span, h1, h2, h3, h4, h5, strong, a, b {
                        font-family: 'Montserrat', sans-serif !important;
                    }

                    img {
                        visibility: hidden;
                        max-height: 0.5em;
                    }
                }
            }

            .footer {
                .controls {
                    .layout-link {
                        .layout-button {
                            justify-content: flex-start;
                            font-size: 1.5em;

                            .button {
                                gap: 0.5em;

                                .button-content {
                                    color: ${vars.colorTertiary}
                                }

                                .button-icon-image {
                                    width: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        .single-article-container {
            flex-direction: column;

            &__image {
                max-height: 200px;
                min-height: 200px;
            }
        }
    }

    @media all and (max-width: ${vars.mobileM}) {
        .single-article-container {
            &__information {
                min-width: 100%;
            }
        }
    }
`;
