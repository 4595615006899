import { FunctionComponent } from 'react';
import Image from 'next/image';

import { Routes } from 'types/routes';

import { withVariables } from 'utils/string';

import PointedArrowRightPurple from 'theme/icons/pointed-arrow-right-purple.svg';

import Button from 'components/layout/Button';
import { ButtonIconTypes, ButtonStyles, ButtonVariants } from 'components/layout/Button/types';
import Link from 'components/layout/Link';

import StyledComponent from './styles';
import { Props } from './types';

const SectionArticlesTripleArticle: FunctionComponent<Props> = ({ articles, translations }) => {
    if (articles?.elements?.length === 0) return null;

    return (
        <StyledComponent className="triple-article">
            <div className="triple-article-container">
                <div className="triple-article-container__left">
                    <div className="post-card">
                        <div className="post-card__image">
                            <Image
                                layout="fill"
                                src={articles.elements[0]?.image?.imageUrl || '/images/home/placeholder_post-1.png'}
                                alt={articles.elements[0]?.category?.name}
                            />
                            <Image
                                className="background"
                                layout="fill"
                                src={articles.elements[0]?.image?.imageUrl || '/images/home/placeholder_post-1.png'}
                                alt={articles.elements[0]?.category?.name}
                                loading="lazy"
                            />
                        </div>
                        <div className="post-card__content">
                            <div className="wrapper p-4">
                                <div className="date-category">
                                    <span className="date">
                                        {articles.elements[0]?.publishedAt}
                                    </span>
                                    <span className="category">
                                        {articles.elements[0]?.category?.name}
                                    </span>
                                </div>
                                <div className="title">
                                    {articles.elements[0]?.title}
                                </div>
                                <div className="learn-more">
                                    <Link href={withVariables(Routes.PublicArticles, { slug: articles.elements[0]?.slug })}>
                                        <Button
                                            className="learn-more-button"
                                            style={ButtonStyles.Secondary}
                                            variant={ButtonVariants.Tertiary}
                                            label={translations?.readMoreButton}
                                            icon={{
                                                type: ButtonIconTypes.Image,
                                                value: PointedArrowRightPurple.src,
                                                position: 'after',
                                            }}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="triple-article-container__right">
                    {articles.elements.map((article, index) => {
                        if (index === 0) return;

                        return (
                            <div
                                key={article.id}
                                className="post-card"
                            >
                                <div className="post-card__image">
                                    <Image
                                        layout="fill"
                                        src={article?.image?.imageUrl || `/images/home/placeholder_post-${index + 1}.png`}
                                        alt={article?.category?.name}
                                    />
                                    <Image
                                        className="background"
                                        layout="fill"
                                        src={article?.image?.imageUrl || `/images/home/placeholder_post-${index + 1}.png`}
                                        alt={article?.category?.name}
                                        loading="lazy"
                                    />
                                </div>
                                <div className="post-card__content">
                                    <div className="date-category">
                                        <span className="date">
                                            {article?.publishedAt}
                                        </span>
                                        <span className="category">
                                            {article?.category?.name}
                                        </span>
                                    </div>
                                    <div className="title">
                                        {article.title}
                                    </div>
                                    <div className="learn-more">
                                        <Link href={withVariables(Routes.PublicArticles, { slug: article.slug })}>
                                            <Button
                                                className="learn-more-button"
                                                style={ButtonStyles.Secondary}
                                                variant={ButtonVariants.Tertiary}
                                                label={translations?.readMoreButton}
                                                icon={{
                                                    type: ButtonIconTypes.Image,
                                                    value: PointedArrowRightPurple.src,
                                                    position: 'after',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </StyledComponent>
    );
};

export default SectionArticlesTripleArticle;
