import ApiArticle from 'types/api/Article';
import { ApiResources } from 'types/apiResources';
import { Endpoints, ListParamsDefault } from 'types/endpoints';
import Element from 'types/redux/Element';
import ListCollection from 'types/redux/ListCollection';

import Article from 'models/Article';

import { withVariables } from 'utils/string';
import { request } from 'services/Api';

import { ELEMENT_MARKUP } from '../consts/api';

export interface ListParams extends ListParamsDefault {}
export interface ListResponse {
    [ApiResources.Articles]: ListCollection<Article>;
}
export const list = async (params: ListParams): Promise<ListResponse> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicArticles, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource: ListCollection<ApiArticle> = data && data[ApiResources.Articles];

    if(!data || !resource) {
        return null;
    }

    return {
        [ApiResources.Articles]: {
            ...resource,
            elements: resource.elements.map(element => new Article(element)),
        },
    };
};

export interface SingleParams {
    slug: string;
}

export const single = async (params: SingleParams): Promise<Element<Article>> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicArticle, params),
        params,
    });

    const data = response?.payload?.data;
    const resource: ApiArticle = data && data[ApiResources.Article];

    if (!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...ELEMENT_MARKUP,
        isLoaded: true,
        data: new Article(resource),
    };
};
