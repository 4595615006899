import { FunctionComponent } from 'react';

import { Routes } from 'types/routes';

import PointedArrowRightLight from 'theme/icons/pointed-arrow-right-light.svg';

import Button from 'components/layout/Button';
import { ButtonIconTypes, ButtonStyles, ButtonVariants } from 'components/layout/Button/types';
import Link from 'components/layout/Link';

import SectionArticleListArticle from './List';
import SectionArticlesSingleArticle from './Single';
import StyledComponent from './styles';
import SectionArticlesTripleArticle from './Triple';
import { ArticleType, Props } from './types';

// TODO: move article components to SWITCH then inject in jsx
const SectionArticles: FunctionComponent<Props> = ({
    translations,
    articles,
    visibleMoreArticlesButton,
    articleType,
    excludeFirstArticle,
}) => {
    return (
        <StyledComponent className="section-articles">
            {translations?.headline && (
                <div className="headline">
                    <h2>
                        {translations?.headline}
                    </h2>
                </div>
            )}
            {translations?.subheadline && (
                <div className="subheadline">
                    {translations?.subheadline}
                </div>
            )}
            {articleType === ArticleType.List &&
                <SectionArticleListArticle {...{ articles, excludeFirstArticle, translations }} />}
            {articleType === ArticleType.Single &&
                <SectionArticlesSingleArticle {...{ articles, translations }} />}
            {articleType === ArticleType.Triple &&
                <SectionArticlesTripleArticle {...{ articles, excludeFirstArticle, translations }} />}
            {visibleMoreArticlesButton && (
                <div className="cta-more-articles">
                    <Link
                        href={Routes.PublicBlog}
                    >
                        <Button
                            style={ButtonStyles.Primary}
                            variant={ButtonVariants.Tertiary}
                            label="Zobacz Więcej"
                            icon={{
                                type: ButtonIconTypes.Image,
                                value: PointedArrowRightLight.src,
                                position: 'after',
                            }}
                        />
                    </Link>
                </div>
            )}
        </StyledComponent>
    );
};

export default SectionArticles;
